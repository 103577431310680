var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('VueSlickCarousel', _vm._b({
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '.slick-slide': {
          padding: '0 16px 8px 0',
          '@media(max-width: 767px)': {
            padding: '0 8px 8px 0'
          }
        }
      },
      expression: "{\n      '.slick-slide': {\n        padding: '0 16px 8px 0',\n        '@media(max-width: 767px)': {\n          padding: '0 8px 8px 0',\n        },\n      },\n    }"
    }]
  }, 'VueSlickCarousel', _vm.settings, false), _vm._l(5, function (i) {
    return _c('c-box', {
      key: i
    }, [_c('c-flex', {
      attrs: {
        "width": "100%",
        "max-width": ['120px', '260px'],
        "flex-direction": "column",
        "background-color": "#FFF",
        "border-radius": ['8px', '12px'],
        "gap": "8px",
        "box-shadow": ['0px 2px 8px 0px #00000026', '2px 2px 10px 0px #00000026'],
        "overflow": "hidden"
      }
    }, [_c('c-box', {
      attrs: {
        "display": "block",
        "width": ['120px', '260px'],
        "height": ['55px', '145px']
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "animation": "fade"
      }
    })], 1), _c('c-box', {
      attrs: {
        "padding-inline": "8px",
        "padding-bottom": "8px"
      }
    }, [_c('c-box', {
      attrs: {
        "display": "block",
        "width": "70%",
        "height": ['18px', '20px']
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "animation": "fade",
        "rounded": ""
      }
    })], 1)], 1)], 1)], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }