<template>
  <c-box>
    <VueSlickCarousel
      v-chakra="{
        '.slick-slide': {
          padding: '0 16px 8px 0',
          '@media(max-width: 767px)': {
            padding: '0 8px 8px 0',
          },
        },
      }"
      v-bind="settings"
    >
      <c-box
        v-for="(i) in 5"
        :key="i"
      >
        <c-flex
          width="100%"
          :max-width="['120px', '260px']"
          flex-direction="column"
          background-color="#FFF"
          :border-radius="['8px', '12px']"
          gap="8px"
          :box-shadow="['0px 2px 8px 0px #00000026', '2px 2px 10px 0px #00000026']"
          overflow="hidden"
        >
          <c-box
            display="block"
            :width="['120px', '260px']"
            :height="['55px', '145px']"
          >
            <vue-skeleton-loader
              type="rect"
              width="100%"
              height="100%"
              animation="fade"
            />
          </c-box>
          <c-box
            padding-inline="8px"
            padding-bottom="8px"
          >
            <c-box
              display="block"
              width="70%"
              :height="['18px', '20px']"
            >
              <vue-skeleton-loader
                type="rect"
                width="100%"
                height="100%"
                animation="fade"
                rounded
              />
            </c-box>
          </c-box>
        </c-flex>
      </c-box>
    </VueSlickCarousel>
  </c-box>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  components: {
    VueSlickCarousel,
    VueSkeletonLoader,
  },
  data() {
    return {
      settings: {
        dots: false,
        arrows: false,
        autoplay: false,
        infinite: false,
        variableWidth: true,
        slidesToScroll: 1,
      },
    }
  }, 
}
</script>

<style>

</style>